import "./assets/sass/_normalize.scss";
import "./assets/sass/_typography.scss";
import "../node_modules/noty/lib/noty.css";

import { React, useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";

import { calcRemainTime } from "./util/time";
import useError from "./util/hooks/use-error";
import HourService from "./services/HourService";
import { hourActions } from "./store/hour";
import { authActions, loginAndGetUserData } from "./store/auth";
import getTheme from "./themes/base";

import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";

import BackStage from "./pages/BackStage";
import Loader from "./components/Loader";

import Home from "./pages/Home";
const News = lazy(() => import("./pages/News"));
const NewsInfo = lazy(() => import("./pages/News/NewsInfo"));
const Activities = lazy(() => import("./pages/Activities"));
const ActivityInfo = lazy(() => import("./pages/ActivityInfo"));
const About = lazy(() => import("./pages/About"));
const Volunteer = lazy(() => import("./pages/Volunteer"));
const Issues = lazy(() => import("./pages/Issues"));
const Rules = lazy(() => import("./pages/Rules"));
const Downloads = lazy(() => import("./pages/Downloads/Downloads"));
const User = lazy(() => import("./pages/User"));
const QA = lazy(() => import("./pages/QA"));
const ScholarshipApply = lazy(() => import("./pages/Apply/ScholarshipApply/ScholarshipApply"));
const Group = lazy(() => import("./pages/Group"));
const EventApply = lazy(() => import("./pages/Apply/EventApply"));
const HourApply = lazy(() => import("./pages/Apply/HourApply"));
const Meetings = lazy(() => import("./pages/Meetings"));
const QrCodeRedirect = lazy(() => import("./pages/QrCodeRedirect"));
const NotFound = lazy(() => import("./pages/Error/404"));

const App = (props) => {
    const theme = useSelector((state) => state.theme.currentTheme);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { errorHandler } = useError();
    const history = useHistory();
    const location = useLocation();
    const appliedTheme = getTheme(theme);
    const isLogged = useSelector(state => state.auth.isLoggedIn);
    const userPermission = useSelector(
        (state) => state.auth.data.permission ?? []
    );

    const retrieveStoredToken = () => {
        const storedToken = localStorage.getItem("token");
        const storedExpirationDate = localStorage.getItem("expirationTime");

        const remainingTime = calcRemainTime(storedExpirationDate);

        if (remainingTime <= 3600) {
            localStorage.removeItem("token");
            localStorage.removeItem("expirationTime");
            return null;
        }

        return {
            token: storedToken,
            expTime: storedExpirationDate,
            duration: remainingTime,
        };
    };

    useEffect(() => {
        const tokenData = retrieveStoredToken();
        if (tokenData) {
            dispatch(
                loginAndGetUserData({
                    token: tokenData.token,
                    expTime: tokenData.expTime,
                    history: history,
                },(err = null) => {
                    if (err) errorHandler(err);
                })
            );
        } else {
            dispatch(authActions.logout());
        }
    }, [dispatch, history, errorHandler]);

    useEffect(() => {
        HourService.getGroupList()
            .then((res) => {
                dispatch(hourActions.updateGroups({ groups: res.data }));
            })
            .catch((err) => errorHandler(err));
        HourService.getLatestRelation()
            .then((hrRelation) => {
                dispatch(hourActions.updateRelation(hrRelation));
            })
            .catch((err) => errorHandler(err));
    }, [dispatch, errorHandler]);

    return (
        <ThemeProvider theme={appliedTheme}>
            <div className={`${classes.App} App`}>
                <Switch>
                    {(userPermission.includes("admin") || userPermission.includes("part_time")) &&
                        isLogged && (<Route path="/admin" component={BackStage} />)
                    }
                    {userPermission.includes("organization") &&
                        isLogged && (<Route path="/office/:officeId" component={BackStage} />)
                    }
                    <Route path="/" render={() => (<>
                        <Header />
                        <main
                            style={{
                                overflow: 'hidden',
                                backgroundColor: appliedTheme.palette.background.default,
                                minHeight: "80vh",
                            }}
                        >
                            <Suspense fallback={
                                <div style={{
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: appliedTheme.palette.background.default,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <Loader className="suspense-loader" />
                                </div>
                            }>
                                <AnimatePresence exitBeforeEnter>
                                    <Switch
                                        location={location}
                                        key={location.pathname.split("/")[1]}
                                    >
                                        <Route path="/" exact component={Home} />
                                        <Route path="/signin/callback" component={Home} />
                                        <Route path="/news" exact component={News} />
                                        <Route path="/news/newsInfo/:id" component={NewsInfo} />
                                        <Route path="/activities" exact component={Activities} />
                                        <Route path="/activities/activityInfo/:id" component={ActivityInfo} />
                                        <Route path="/about" component={About} />
                                        <Route path="/volunteer" component={Volunteer} />
                                        <Route path="/issues" component={Issues} />
                                        <Route path="/rules" component={Rules} />
                                        <Route path="/downloads" component={Downloads} />
                                        <Route path="/meetings" component={Meetings} />
                                        <Route path="/qa" component={QA} />
                                        <Route path="/signoutQrCode/:id" component={QrCodeRedirect} />
                                        <Route path="/scanSigninQrcode/:id" component={QrCodeRedirect} />
                                        {isLogged && (<>
                                            {userPermission.includes("student") && (
                                                <Switch>
                                                    <Route path="/user" component={User} />
                                                    <Route path="/apply/event/:id" component={EventApply} />
                                                    <Route path="/apply/scholarship" component={ScholarshipApply} />
                                                    <Route path="/apply/hour/:id" component={HourApply} />
                                                    {/* <Route path="/apply/hour" component={HourApply} /> */}
                                                    <Route path="/apply/hour" render={() => {
                                                        <>
                                                            Redirect to iNCU ...
                                                            <br />
                                                            <div style={{ display: 'none' }}>
                                                                {window.location = "https://cis.ncu.edu.tw/iNCU/stdAffair/serl/serlPersonalHourApply"}
                                                            </div>
                                                        </>
                                                    }} />
                                                </Switch>
                                            )}
                                            {userPermission.includes("organization") && (
                                                <Switch>
                                                    <Route path="/group/:groupId/apply/event/:id" component={EventApply} />
                                                    <Route path="/group/:groupId/apply/event" component={EventApply} />
                                                    <Route path="/group/:groupId/activityInfo/:id" component={ActivityInfo} />
                                                    <Route path="/group/:groupId" component={Group} />
                                                </Switch>
                                            )}
                                        </>)}
                                        <Route path="/" component={NotFound} />
                                    </Switch>
                                </AnimatePresence>
                            </Suspense>
                        </main>
                        <Footer />
                    </>)} />
                    <Route path="/" component={NotFound} />
                </Switch>
            </div>
        </ThemeProvider>
    );
};

export default App;

const useStyles = makeStyles((theme) => ({
    "@keyframes fadeIn": {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
    App: {
        "& .suspense-loader": {
            opacity: 0,
            animationFillMode: "forwards",
            animation: "$fadeIn 1s",
            animationDelay: "0.75s",
        },
    }
}));
